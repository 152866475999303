@use 'typography' as *;
@use 'vars' as *;
@use 'direction' as *;
@use 'blocks';
@use 'spinner';
@use 'sass:string';

:root {
  --ln-color-primary: #152647;
  --ln-color-primary-rgb: 21, 38, 71;
  --ln-color-primary-light: #334150;
  --ln-color-secondary: #fb8a13;
  --ln-color-secondary-rgb: 251, 138, 19;
  --ln-color-secondary-rgb-20: rgba(var(--ln-color-secondary-rgb), 0.24);
  --ln-color-background: #fff;
  --ln-color-background-rgb: 255, 255, 255;
  --ln-color-background-gray: #f4f5f7;
  --ln-color-background-gray-rgb: 244, 245, 247;
  --ln-color-background-gray2: #f0f5fa;
  --ln-color-background-gray3: #eef2f5;
  --ln-color-background-gray4: #f8f9fa;
  --ln-color-background-blue: #eef4ff;
  --ln-color-border-gray: #dfe1e6;
  --ln-color-border-gray2: #e6e9ed;
  --ln-color-background-orange: #ffefcc;
  --ln-color-text-gray: #bfc7d1;
  --ln-color-text-gray-rgb: 191, 199, 209;
  --ln-color-text-gray-08: rgba(var(--ln-color-text-gray-rgb), 0.08);
  --ln-color-text-gray-20: rgba(var(--ln-color-text-gray-rgb), 0.24); /* #f0f2f4 */
  --ln-color-text-gray-dark: #7f8fa4;
  --ln-color-header: #2933a7;
  --ln-color-success: #6fda44;
  --ln-color-success-rgb: 111, 218, 68;
  --ln-color-warning: #ffca54;
  --ln-color-warning-rgb: 255, 202, 84;
  --ln-color-error: #ff375f;
  --ln-color-error-rgb: 255, 55, 95;
  --ln-color-active: #25a2fe;
  --ln-color-selected: #0a84ff;
  --ln-color-selected-rgb: 10, 132, 255;
  --ln-color-green: #0ec254;
  --ln-color-green-background: #cffce1;
  --ln-color-divider: rgba(var(--ln-color-text-gray), 0.8);
  --ln-color-shadow: #b1c9e5;
  --mrg-row: 24px;
  --mrg-col: 24px;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Fira Sans', sans-serif;
  color: var(--ln-color-primary);
  background-color: var(--ln-color-background);
  margin: 0;
  position: relative;
  word-wrap: break-word;

  @include common-text;

  div.ngxp__container {
    @include small-text;

    color: var(--ln-color-primary);
    background: var(--ln-color-background);
    box-shadow: 0 0 40px 6px var(--ln-color-text-gray-20);
    border: 0;
    max-width: 250px;
    z-index: 1000;
    border-radius: 12px;
    padding: 16px 24px;

    > .ngxp__arrow {
      background-color: var(--ln-color-background);
    }
  }

  @media screen and (max-width: $laptop) {
    --mrg-row: 16px;
    --mrg-col: 8px;
  }
}

a:not(.decoration-link) {
  color: var(--color-link, inherit);
  text-decoration: none;
  transition:
    color 0.3s,
    filter 0.3s;
}

a.decoration-link {
  color: var(--ln-color-secondary);

  &:hover {
    filter: brightness(90%);
  }
}

.secondary-link {
  --color-link: var(--ln-color-secondary);

  &:hover {
    filter: brightness(90%);
  }
}

p {
  margin-top: 14px;
  margin-bottom: 14px;
}

.highlighted-text {
  background-color: var(--ln-color-secondary);
}

hr {
  background: rgba(var(--ln-color-text-gray-rgb), 0.2);
  height: 1px;
  border: none;
  margin: 16px 0;
}

.fi {
  border-radius: 4px;
}

label[required]::after {
  content: ' *';
}

iframe {
  border: 0px;
}

.app-container {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  --pd-width: #{$container-padding};
  --pd-end: var(--pd-width);

  @media screen and (max-width: $mobile) {
    --pd-width: #{$container-padding-mobile};
  }

  @media screen and (min-width: calc($laptop + 1px)) {
    --pd-end: 130px;
  }

  @media screen and (min-width: calc($container-width + 30px)) {
    --pd-end: calc((1285px - 100vw) + ((100vw - 1140px) / 2));
  }

  @include rtl-value(padding, 0 var(--pd-end) 0 var(--pd-width), 0 var(--pd-width) 0 var(--pd-end), false);
}

.app-container-full {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 15px;
}

* {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

$space: (0, 4, 8, 16, 24, 32, 48);
$pos: (top, bottom, left, right);

@each $s in $space {
  .m-#{$s} {
    margin: #{$s}px;
  }
  .p-#{$s} {
    padding: #{$s}px;
  }

  @each $p in $pos {
    .m#{string.slice($p, 0, 1)}-#{$s} {
      @if $p == left {
        @include margin-start(#{$s}px, false);
      } @else if $p == right {
        @include margin-end(#{$s}px, false);
      } @else {
        margin-#{$p}: #{$s}px;
      }
    }
    .p#{string.slice($p, 0, 1)}-#{$s} {
      @if $p == left {
        @include rtl-prop(padding-right, padding-left, #{$s}px, false);
      } @else if $p == right {
        @include rtl-prop(padding-left, padding-right, #{$s}px, false);
      } @else {
        padding-#{$p}: #{$s}px;
      }
    }
  }
}

.p-adaptive {
  padding: var(--mrg-row) var(--mrg-col);
}

.m-adaptive {
  margin: var(--mrg-row) var(--mrg-col);
}
