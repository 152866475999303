@use 'vars' as *;
@use 'typography' as *;

.blocks {
  &__title {
    @include title-text;

    & {
      border-bottom: 1px solid var(--ln-color-text-gray-20);
    }
  }

  &__block {
    padding: var(--mrg-row);

    @media screen and (max-width: $laptop) {
      padding: var(--mrg-row) var(--mrg-col);
    }
  }
}
